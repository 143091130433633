#ssNavMenu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ss-nav-menu-item {
  display: inline;
  padding-right: 30px;
}
.ss-nav-menu-item:last-child {
  padding-right: 0;
}

@media all and (min-width: 1000px) {
  #ssNavMenu {
    position: relative;
  }

  .ss-close-left {
    display: none;
  }

  .ss-nav-menu-item {
    position: relative;
  }
}

@media all and (max-width: 999px) {
  #ssNavMenu {
    margin-top: 70px;
  }
  #ssNavMenuContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #ffffff;
    z-index: 99999;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  #ssNavMenuContainer.ss-show {
    /*display: unset !important;*/
    visibility: visible;
    opacity: 1;
  }

  #ssNavMenuContainer.ss-hidden {
    /*display: none !important;*/
    visibility: hidden;
    opacity: 0;
  }

  .ss-close-left {
    position: fixed;
    top: 25px;
    left: 25px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  #ssNavMenuContainer::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 250px;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  #ssNavMenu {
    border-top: solid 1px #dddddd;
  }

  .ss-nav-menu-item {
    display: block;
    padding: 20px 0 0 27px;
  }

  #menuCloser {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    z-index: 99999;
    pointer-events: none;
  }

  #menuCloser::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 250px;
    margin: 0;
    pointer-events: auto;
  }
}

.ss-nav-menu-item a,
.ss-nav-menu-item span.pointer {
  text-decoration: none;
  color: #324559;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.3;
  text-align: right;
  position: relative;
}

.ss-nav-menu-item a:hover,
.ss-nav-menu-item span.pointer:hover {
  /* font-weight: 300; */
  border-bottom: 2px solid #f79019;
}

.ss-nav-menu-item a:link,
.ss-nav-menu-item span.pointer:link {
  background-color: transparent !important;
}

.ss-nav-menu-item a:active,
.ss-nav-menu-item span.pointer:active {
  color: #999999 !important;
  background-color: transparent !important;
}

.ss-nav-menu-item a.active,
.ss-nav-menu-item span.pointer.active {
  /*color: #555555 !Important;*/
  color: #f79019 !important;
  background-color: transparent !important;
}

.ss-nav-menu-item-highlighted {
  display: inline-block;
  border-radius: 21px;
  background-color: #f79019;
  color: #ffffff !important;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 0.3px;
}

.ss-nav-submenu-toggle .caret {
  margin-left: 4px !important;
  margin-bottom: 3px !important;
}

.no-link-hover:hover {
  border-bottom: none !important;
}
