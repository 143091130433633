.selectDownDiv select {
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.selectDownDiv label {
  margin-bottom: 5px;
}
