.multi-select-content-wrapper {
  position: relative;
  width: 100%;
}

.multi-select-dropdown-content {
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
}

.multi-select-dropdown-content-editWidth {
  width: 100%; /* Adjust as necessary */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
}

/* .multi-select-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ccc;
}

.multi-select-button {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
}

.multi-select-button:hover {
  background-color: #0056b3;
} */
.multi-select-input input.read-only {
  cursor: pointer;
  font-weight: bold; /* Cursor style for read-only input */
}


.dropdown-symbol {
  margin-right: 5px;
}
