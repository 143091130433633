.manage-existing-users-container {
    display: flex;
    flex-flow: column;
    min-width: 450px;
}
.pagination {
    display: flex;
    justify-content: space-around;
}
.manage-existing-users-container-supplier {
    min-width: 500px;
}

.manage-users-header {
    display: flex;
    flex-flow: row;
}

.manage-users-header-item {
    flex: 1;
    color: #5d6b7a;
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px 24px;
}

.actions-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.existing-users-container {
    border: 1px solid #e1e1e1;
    border-radius: 3px 3px 0 0;
}

.center-text {
    text-align: center;
}

.ss-select-options-visiblemanage {
    position: absolute;
    margin-left: 27%;
    margin-top: -20px;
}

.ss-select-options-visiblemanage_li {
    font-weight: bolder;
    margin: 3px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    transition: 0.3s;
}

.ss-select-options-visiblemanage_li:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}