.mt2 {
  margin-top: -2%;
}
.mt23 {
  margin-top: 23px;
}
.mt27 {
  margin-top: -27px;
}
.mt40 {
  margin-top: 40px;
}
.width200 {
  width: 200px;
}
.mt-align {
  margin-top: 17px;
  text-align: center;
}
.mb30 {
  margin-bottom: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.errorMessage {
  color: red;
  width: 200%;
}
.pointerEvent {
  pointer-events: none;
  opacity: 0.5;
  width: 200px;
}
.padding {
  padding: 20px 30px;
}
.pb30 {
  padding: 30px;
}
.text-align {
  text-align: center;
}
.bgColor {
  background-color: #eeeeee;
}
.cursorNotAllow {
  cursor: not-allowed;
}
.custom-cursor {
  cursor: "not-allowed";
  opacity: "inherit";
  font-weight: "500";
}
.no-cursor {
  opacity: "inherit";
  font-weight: "500";
}
.pointer-events {
  pointer-events: none;
  opacity: 0.5;
}

.verticalAlign {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}
.verticalAlign-custom {
  margin-left: 24px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.alignDisplay {
  margin-top: 4px;
  display: inline-block;
  vertical-align: middle;
}
.element-class {
  margin-top: 4px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  cursor: not-allowed;
}

.element-class-name {
  margin-top: 4px;
  display: inline-block;
  vertical-align: middle;
}
.width100 {
  width: 100%;
}

.margin-top-27 {
  margin-top: -27px;
}
.displayFlex {
  display: flex;
  justify-content: space-between;
}
.widthOpacity {
  width: 200px;
  opacity: 0.5;
}
