.custom-dropdown {
  position: relative;
  width: 200px; /* Adjust width as needed */
  cursor: pointer;
}

.dropdown-header {
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px; /* Optional: limit dropdown height */
  overflow-y: auto; /* Enable scrolling if there are many items */
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #e0e0e0; /* Custom hover color */
}

.dropdown-item.selected {
  background-color: #4a90e2; /* Custom selected color */
  color: white;
}
