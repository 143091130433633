.ss-report-form-date {
  width: auto;
  font-family: inherit;
  text-indent: initial;
  margin: 8px 25px 13px 25px;
  padding: 0;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.24;
  text-align: left;
  color: #555555;
  background-color: #ffffff !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ss-report-form-date-fieldset {
  min-width: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0 10em 0 0;
  border: none;
  display: inline-block;
}

.ss-report-form-fine-date-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.ss-report-form-date {
  pointer-events: auto;
  cursor: pointer;
}
