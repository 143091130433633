.clear {
  clear: both;
}

.create-location {
  margin: 30px auto;
}

.create-location thead {
  border-bottom: solid 1px #dddddd;
}

.create-location tr {
  border-bottom: solid 1px #dddddd;
}

.eq-type {
  padding-top: 60px;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.up-margin + form .form-group {
  margin-top: 25px;
}

.up-margin + form label {
  font-weight: normal;
}

.prices-box {
  padding: 0;
}
.prices-box > div.col-sm-4 {
  margin-bottom: 10px;
}
.prices-box > div > div:first-child {
  border: 1px solid #ddd;
  border-radius: 15px;
  text-align: center;
}

.prices-box > div > div > p:first-child {
  display: table;
  margin: -7px auto 0;
  padding-left: 2px;
  padding-right: 2px;
  background: #fff;
  position: relative;
  font-size: 10px;
}

.prices-box > div input {
  border: none;
  width: 95%;
  margin: 0 auto 5px;
  text-align: center;
}

.calculated-field {
  font-size: 10px;
  width: 100%;
  text-align: center;
}

#ssCreateEditLocation .ss-summary {
  padding-top: 10px;
  width: 100%;
  float: left;
}
#ssCreateEditLocation .ss-top-summary {
  padding-top: 30px;
}
#ssCreateEditLocation form {
  padding: 0;
}
#ssLocationProfileFormContainer {
  padding: 0 50px;
}

#ssCreateEditLocation .modal-footer {
  padding: 17px 50px;
}

#ssCreateEditLocation .modal-footer .ss-button-primary {
  border: 1px solid #f29100;
  border-radius: 17.5px;
  background-color: #f09019;
  margin-right: 30px;
  margin-top: 0;
}
#ssCreateEditLocation .ss-button-primary.reverse:active {
  background-color: #fff;
}
#ssCreateEditLocation .modal-footer .ss-button-secondary:active {
  border: none;
  transform: none;
  box-shadow: none;
}
#ssCreateEditLocation .modal-footer .ss-button-secondary {
  background-color: transparent;
  border: none;
}

@media all and (min-width: 1300px) {
  .ss-close-large-screen {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 30px;
    width: 30px;
    z-index: 9;
    cursor: pointer;
  }

  #ssCreateEditLocation.ss-main > header {
    height: 100%;
    width: 520px;
    margin-top: -70px;
  }

  #ssCreateEditLocation.ss-main > header > h1 {
    padding-top: 187px;
    padding-right: 100px;
    padding-left: 100px;
  }

  #ssCreateEditLocation.ss-main > section {
    position: absolute;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 520px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  #ssCreateEditLocation .ss-button-danger {
    width: 280px;
    margin-right: 10px;
  }

  #ssCreateEditLocation .ss-button-primary {
    width: 254px;
    margin-right: 0;
  }
}

@media all and (min-width: 1000px) and (max-width: 1299px) {
  .ss-close-large-screen {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 30px;
    width: 30px;
    z-index: 9;
    cursor: pointer;
  }

  #ssCreateEditLocation.ss-main > header {
    height: 100%;
    width: 40%;
    margin-top: -70px;
  }

  #ssCreateEditLocation.ss-main > header > h1 {
    padding-top: 187px;
    padding-right: 100px;
    padding-left: 100px;
  }

  #ssCreateEditLocation.ss-main > section {
    position: absolute;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 40%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  #ssCreateEditLocation .ss-button-danger {
    margin-right: 0;
  }

  /* #ssCreateEditLocation .ss-button-primary {
        margin-top: 10px;
        margin-right: 0;
    } */
}

@media all and (max-width: 999px) {
  #ssCreateEditLocation.ss-main > header footer {
    display: none;
  }

  .ss-close-large-screen {
    position: absolute;
    top: 20px;
    right: 0;
    height: 20px;
    width: 20px;
    z-index: 9;
    cursor: pointer;
  }

  #ssCreateEditLocation.ss-main > header {
    height: 86px;
    width: 100%;
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
  }

  #ssCreateEditLocation.ss-main > header > h1 {
    position: absolute;
    top: 30px;
    left: 27px;
  }

  #ssCreateEditLocation.ss-main > section {
    position: absolute;
    top: 156px;
    right: 27px;
    bottom: 0;
    left: 27px;
  }

  #ssCreateEditLocation .ss-button-danger {
    margin-right: 0;
  }

  /* #ssCreateEditLocation .ss-button-primary {
        margin-top: 10px;
        margin-right: 0;
    } */
}

.glyphicon-info-sign {
  padding-right: 4px;
  font-size: 15px;
}

#ssCreateEditLocation.ss-main > header {
  background-color: #28b8f6;
  background-image: none;
}

#ssCreateEditLocation.ss-main > hr {
  position: absolute;
  top: 70px;
  height: 1px;
  width: 100%;
  color: rgba(34, 34, 34, 0.1);
  background-color: rgba(34, 34, 34, 0.1);
  z-index: 9998;
}

#ssLocationProfileFormContainer .ss-danger-box,
#ssLocationProfileFormContainer .ss-success-box {
  width: auto;
  position: absolute;
  top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

#ssCreateEditLocation .ss-checkbox {
  width: 285px;
  display: block;
  margin: 0 0 0 0 !important;
  font-size: 16px;
  text-align: left;
  color: #777777;
}

#ssCreateEditLocation input[type="checkbox"] {
  margin: 0 11px 1px 0;
  padding: 0;
}

#ssCreateEditLocation .ss-checkbox > span {
  font-size: 16px;
  text-align: left;
  color: #777777;
}
.checkbox-outline {
  outline: 0 !important;
}

.location-items-column {
  display: inline-block;
  vertical-align: top;
}
.flex-center.m-b {
  margin-bottom: 25px;
  width: 100%;
  float: left;
}
.m-t {
  margin-top: 30px;
}
.toggle-section {
  width: 100px;
}
.location-name-tip {
  margin-left: 10px;
  font-size: 13px !important;
  color: #28b8f6;
}
.location-tooltip-facility.location-name-tip-hover {
  margin-top: 0;
  top: -90px !important;
  left: 55px !important;
  position: absolute !important;
}
.location-tooltip-top.location-name-tip-hover {
  margin-top: 0;
  top: -72px !important;
  left: 16px !important;
  position: absolute !important;
}
.position-relative {
  position: relative !important;
}

.location-name-tip-hover {
  background-color: #28b8f6 !important;
  max-width: 200px;
  /* top: 40px;
    opacity: 1; */
}

.location-name-tip-hover::after {
  border-top-color: #28b8f6 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.location-name-tip-hover span {
  color: #ffffff !important;
}

.ss-create-edit-location-header {
  display: flex;
  align-items: stretch;
  margin: 35px 0;
  font-size: 40px;
  font-weight: bold;
  color: #313c41;
}

.ss-create-edit-location-header-placeholder {
  padding-left: 50px;
  width: 33%;
}

.ss-create-edit-location-header-close {
  text-align: right;
  padding-right: 50px;
}

#ssCreateEditLocation .ss-summary {
  margin-bottom: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #dddddd;
  opacity: 0.74;
  color: #5d6b7a;
  font-size: 15px;
  font-weight: 500;
}

.border-bottom-blue-line {
  padding-bottom: 5px;
  border-bottom: 3px solid #19c6f0;
}

#ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.price-month {
  float: right;
  width: 47%;
}
#ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.price-day {
  width: 47%;
  float: left;
}
#ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.ss-entry {
  width: 47%;
}
#ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field {
  border: 1px solid #b1b8bf;
  border-radius: 4px;
  margin-bottom: 25px;
}

#ssCreateEditLocation form.ss-form.ss-block .hs-field label {
  margin: 0;
  margin-left: 5px;
  padding: 0 5px;
  font-weight: 600;
  letter-spacing: 0.7px;
  white-space: nowrap;
  /*line-height: 13px;*/
  width: auto;
  top: -7px;
  position: relative;
  background: #fff;
  display: table;
  font-size: 10px;
}

#ssCreateEditLocation form.ss-form.ss-block .hs-field input {
  border: 0 none;
  padding-right: 7px !important;
  font-weight: 500;
  outline: none;
  padding-left: 10px !important;
  top: -3px;
  position: relative;
  margin: 0 !important;
  max-width: 100%;
}
#ssCreateEditLocation form.ss-form.ss-block .hs-field input[name="country"] {
  border: 0 none;
  padding-right: 7px !important;
  font-weight: 500;
  outline: none;
  padding-left: 10px !important;
  top: -3px;
  position: relative;
  margin: 0 !important;
  max-width: 100%;
  background-color: #eeeeee !important;
}
#ssCreateEditLocation
  form.ss-form.ss-block
  .hs-field
  input[name="bankNameBookSpace"] {
  border: 0 none;
  padding-right: 7px !important;
  font-weight: 500;
  outline: none;
  padding-left: 10px !important;
  top: -3px;
  caret-color: transparent;
  cursor: not-allowed;
  background-color: #eeeeee !important;
  position: relative;
  margin: 0 !important;
  max-width: 100%;
}

#ssCreateEditLocation
  form.ss-form.ss-block
  .hs-field
  input[name="bankNameStatic"] {
  border: 0 none;
  padding-right: 7px !important;
  font-weight: 500;
  outline: none;
  padding-left: 10px !important;
  top: -3px;
  position: relative;
  margin: 0 !important;
  max-width: 100%;
  background-color: #efefef !important;
  cursor: not-allowed;
}

#ssCreateEditLocation form.ss-form.ss-block .hs-field textarea {
  border: 0 none;
  padding-right: 7px !important;
  font-weight: 500;
  outline: none;
  padding-left: 10px !important;
  top: -3px;
  position: relative;
  margin: 0 !important;
  max-width: 100%;
}

#locations-map {
  margin-top: 0;
  margin-bottom: 25px;
}

#locations-map > div {
  border-radius: 7px;
}

#ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.ss-stand-alone {
  width: 47%;
}

@media (max-width: 600px) {
  #ssLocationProfileFormContainer {
    padding: 15px;
  }
  #ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.price-day,
  #ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.price-month {
    width: 100%;
    margin: 15px 0;
    float: left;
  }
  #ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.ss-stand-alone {
    width: 100%;
    margin: 15px 0;
    float: left;
  }
  #ssCreateEditLocation .modal-footer {
    padding: 15px;
  }
}

.pac-container {
  z-index: 10001 !important;
}

.eq-error-container {
  height: 30px;
}
