.ss-forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ss-forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ss-forgot-password-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ss-forgot-password-desc {
    font-size: 20px;
    margin-bottom: 0.5em;
    margin-top: 10px;
}

.ss-forgot-password-username {
    width:100%;
}

.ss-forgot-password-username-input {
    font-weight: bold;
    color: #324559;
    font-family: SofiaPro, sans-serif;
    padding: 0.4em 0.5em;
    width: 100%;
    outline: none;
    border: 0;
}

.ss-forgot-password-field {
    margin-bottom: 1.5em;
}

.ss-forgot-password-submit {
    width: 60%;
    margin: 0 auto;
}
