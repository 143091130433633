.password-meter {
    width: 50%;
    float: right;
}

.password-strength-meter-progress {
    width: 33.33%;
    float: left;
    padding: 4px;

}

.password-strength-meter-progress > span {
    border-radius: 5px;
    background-color: #F6F6F6;
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: baseline;
    height: 8px;
}

.password-strength-meter-progress.selected  >span{
    background-color: #F09019;
}
