.paymentContainer {
  padding-top: 30px;
  width: 58%;
  margin: auto;
}
.imageCheckMark {
  display: inline-block;
  float: left;
  width: 70px;
  margin-left: 15px;
  margin-right: 10px;
}
.mt-3px {
  margin-top: 3px;
}
.border-top {
  border-top: 1px solid #ccc; /* Set a top border with a light gray color */
}
.font-weight {
  font-weight: bold;
}

.paymentContainer h1 {
  font-family: sans-serif;
}

.paymentContainer p {
  font-family: sans-serif;
  font-size: "17px";
  font-weight: "500";
}

.tableStyle {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 63%;
}

.tableRow {
  margin-top: 15px;
  display: block;
}
.tableH {
  margin-right: 12px;
  float: left;
  width: 150px;
  font-family: sans-serif;
}
.tableHContent {
  font-family: sans-serif;
}

.paymentSuccessfulButton {
  width: 22%;
  border-radius: 40px;
  margin: 30px auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.securitypaymentSuccessfulButton {
  width: 22%;
  border-radius: 40px;
  margin: 30px auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableStyle {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 63%;
}
@media only screen and (max-width: 1030px) {
  .securitypaymentSuccessfulButton {
    width: 31%;
    border-radius: 40px;
    margin: 30px auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .paymentContainer {
    padding-top: 30px;
    width: 68%;
    margin: auto;
  }
  .securitypaymentSuccessfulButton {
    width: 41%;
    border-radius: 40px;
    margin: 30px auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 680px) {
  .paymentContainer {
    padding-top: 30px;
    width: 100%;
    margin: auto;
  }
  .paymentContainer h1 {
    font-size: 20px;
  }
  .paymentContainer .imageCheckMark {
    width: 50px;
    margin-left: -5px;
    margin-right: 5px;
  }
  .tableStyle {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .paymentSuccessfulButton {
    width: 40%;
    border-radius: 40px;
    margin: 30px auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .securitypaymentSuccessfulButton {
    width: 60%;
    border-radius: 40px;
    margin: 30px auto;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
