.ss-date-arrow {
    display: inline-block;
    text-align: left;
}

.arrow-top-half {
    position: relative;
    top: 6px;
    height: 6px;
    width: 21px;
    border-right: solid 1px #979797;
    border-top: solid 1px #979797;
    -ms-transform: skewX(-43deg); /* IE 9 */
    -webkit-transform: skewX(-43deg); /* Safari */
    transform: skewX(-43deg); /* Standard syntax */
}

.arrow-bottom-half {
    position: relative;
    bottom: 5px;
    height: 6px;
    width: 21px;
    border-right: solid 1px #979797;
    border-bottom: solid 1px #979797;
    -ms-transform: skewX(43deg); /* IE 9 */
    -webkit-transform: skewX(43deg); /* Safari */
    transform: skewX(43deg); /* Standard syntax */
}