.powerAccessibleDiv {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  align-items: center;
}
.powerAccessibleDiv label {
  height: 20px;
  padding: 5px 0;
}
.powerAccessibleDiv .form-control {
  width: 20px;
  height: 20px;
  border: 0 !important;
  float: left;
  margin-right: 10px;
}
.booleanInputAllowBooking {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  align-items: center;
}
.booleanInputAllowBooking label {
  height: 20px;
  padding: 5px 0;
}
.booleanInputAllowBooking .form-control {
  width: 20px;
  height: 20px;
  border: 0 !important;
  float: left;
  margin-right: 10px;
}
.margin-start-date {
  margin-right: 56px;
}
.margin-end-date {
  margin-right: -115px;
  caret-color: transparent;
  width: 200px;
  height: 31px;
  padding: 3px;
  border-radius: 5px solid #ccc;
}
.caretColour {
  caret-color: transparent;
  width: 200px;
  height: 31px;
  padding: 3px;
  border-radius: 5px solid #ccc;
}

.inputDivBox {
  display: flex;
  justify-content: space-between;
}
.inputDivBox div {
  display: block;
  width: 40%;
}
.ss-error-numberOfSpace {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1;
  color: #d74747;
}
