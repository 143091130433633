.btnDivBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto 0;
    width: 100%;
}
.btnDivBox .button{
    width: 200px;
    margin-right: 10px;
}
.goBackBtn{
    width: 200px; 
    margin: 50px auto 0;
}