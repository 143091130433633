.ss-report-title-container {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 3px;
}

.ss-report-title-h3 {
    color: #7C8793;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.ss-report-title-subtext {
    font-size: 14px;
    color: #999999;
    margin-left: 10px;
    font-weight: normal;
 }
