.currencyinput {
  position: absolute;
  margin-top: 27px;
  margin-left: -100px;
}
.currencyinput_per_day {
  position: absolute;
  margin-top: 27px;
  margin-left: -83px;
}
.currencyinput_per_day_one_time {
  position: absolute;
  margin-top: 27px;
  margin-left: -149px;
}
.currencyinput_per_week {
  position: absolute;
  margin-top: 27px;
  margin-left: -90px;
}
.currencyinput_per_month {
  position: absolute;
  margin-top: 27px;
  margin-left: -98px;
}
.currencyinput_per_month_recurring {
  position: absolute;
  margin-top: 27px;
  margin-left: -168px;
}
