#ssApp {
  position: relative;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

#ssSimplifiedCustomerApp {
  position: relative;
  width: 100%;
  height: 100%;
  /*overflow: hidden;*/
  -webkit-overflow-scrolling: touch;
}

#ssApp > div {
  padding: 0 90px;
  width: 100%;
  float: left;
}

#appNav > div {
  position: relative;
  float: left;
  width: 100%;
  /*border-bottom: 1px solid #E7E7E7;*/
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

#appNav + div {
  padding: 0;
  flex: 1;
}
.heightVal {
  height: 265px;
}
.heightCustomVal {
  height: 250px;
}
.mt40 {
  margin-top: 40px;
}
.custom-style {
  /* height: 20px;
  width: 20px; */
  accent-color: black;
  /* outline: 0.5px solid black; */
}
.width200 {
  width: 200px;
}
.gridColumn {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.pb-10px {
  padding-bottom: 10px;
}
.custom-text-bold {
  color: #f09019; /* Set text color */
  font-weight: bold; /* Set font weight to bold */
}
.custom-text-bold-colour {
  color: #42c655; /* Set text color */
  font-weight: bold; /* Set font weight to bold */
}
.custom-text-bold-red {
  color: red; /* Set text color */
  font-weight: bold; /* Set font weight to bold */
}

.widthCustomVal {
  width: 250px;
}
.heightCustomeVal {
  height: 298px;
}
.heigthAndWidth {
  width: 100px;
  height: 100px;
}
.height-500px {
  height: 500px;
}
.custom-margin-top-bottom {
  margin-top: 0px; /* Set top margin to 0 */
  margin-bottom: 20px; /* Set bottom margin to 20 pixels */
}

.flex-center-horizontal {
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center items horizontally */
  width: 100%; /* Set width to 100% */
}

.width123 {
  width: 123%;
}
.opacity-width {
  opacity: 0.5;
  width: 35%;
}
.width35 {
  width: 35%;
}
.width70 {
  width: 70%;
}
.width100 {
  width: 100%;
}
.centered-element {
  position: absolute;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
}
.custom-margin {
  margin-left: 10px; /* Left margin */
  margin-top: 50px; /* Top margin */
  margin-bottom: 50px; /* Bottom margin */
}
.custom-ml18 {
  margin-left: 18%;
  width: 30%;
}
.mb-52px {
  margin-bottom: 52px;
}
.custom-ml5 {
  margin-left: 5%;
  width: 30%;
}
.contentDisplay {
  display: contents;
}
.width49 {
  width: 49%;
}
.borderBottom {
  border-bottom: none;
}
.padding-12px-mb-40px {
  padding: 12px;
  margin-bottom: 40px;
}
.width80-mt32 {
  width: 80%;
  margin-top: 32%;
}
.float-right-margin {
  float: right;
  margin-right: 10px;
}
.float-right-margin-top {
  float: right; /* Float the element to the right */
  margin-top: 0.25%; /* Set the top margin */
  margin-right: 1%; /* Set the right margin */
}

.custom-box {
  margin-right: 10px; /* Set right margin */
  border: 2px solid black; /* Set border */
  height: 260px; /* Set height */
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.ms-10px {
  margin-left: 10px;
}
.mt-10px {
  margin-top: 10px;
}
.mb-10px {
  margin-bottom: 10px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-18px {
  margin-bottom: 18px;
}
.not-allowed-opacity {
  cursor: not-allowed;
  opacity: 0.5;
}
.justify-content {
  display: flex !important;
  justify-content: space-around !important;
}
.justify-content-center {
  display: flex !important;
  justify-content: center !important;
}
.bg-custom-light {
  background-color: #eeeeee;
}
.search #appNav + div {
  padding: 20px 0 0;
}

.landing #appNav > div {
  border: 0;
}

#navMenuContainer {
  margin-left: auto;
}

header {
  padding-top: 30px;
  padding-bottom: 30px;
}
.gm-style-iw-chr {
  position: absolute;
  right: 0px;
  top: 3px;
}
.gm-style .gm-style-iw-d {
  padding-top: 20px;
  width: 300px;
}
.custom-style-border {
  margin-right: 20px; /* Set right margin to 20px */
  border: 1px solid black; /* Set a solid black border */
}
.mt-2 {
  margin-top: -2%;
}
.mt-4 {
  margin-top: 4vh;
}

.custom-border-margin {
  margin-top: -5px; /* Shifts the element up by 5px */
  border: 1px solid #e1e1e1; /* Adds a light gray border */
}
.custom-border-margin-10px {
  margin-top: -10px; /* Shifts the element up by 5px */
  border: 1px solid #e1e1e1; /* Adds a light gray border */
}

.custom-border-marginPositive {
  margin-top: 5px; /* Shifts the element up by 5px */
  border: 1px solid #e1e1e1; /* Adds a light gray border */
}
.session-expired-popup {
  width: 80%;
  margin-top: 18%;
  margin-left: 7%;
}
.custom-style-unset {
  padding-left: 40px; /* Set left padding */
  margin-top: 20px; /* Set top margin */
  min-height: unset; /* Reset minimum height */
}
.mb10px {
  margin-bottom: 10px;
}
.mb11px {
  margin-bottom: 11px;
}
.custom-text-style {
  display: inline-block; /* Set display to inline-block */
  font-size: 13px; /* Set font size */
  font-weight: 400; /* Set font weight */
  color: #999999; /* Set text color */
}
.absolute-center {
  position: absolute; /* Position the element absolutely */
  top: 50%; /* Position it 50% from the top */
  left: 56%; /* Position it 56% from the left */
  transform: translate(-50%, -50%); /* Center the element */
}
.custom-style-font-padding {
  display: inline-block; /* Set display to inline-block */
  font-size: 15px; /* Set font size */
  padding: 3px;
  font-weight: 700; /* Set font weight to bold */
}
.custom-style-font {
  display: inline-block; /* Set display to inline-block */
  font-size: 15px; /* Set font size */
  font-weight: 700; /* Set font weight to bold */
}
.custom-size {
  height: 40px; /* Set height to 40 pixels */
  width: 100px; /* Set width to 100 pixels */
}
.custom-grid {
  background-color: whitesmoke !important; /* Set background color */
  display: grid !important; /* Enable grid layout */
  grid-template-columns: 10% 40% 50% !important; /* Define grid columns */
}

.popup-header {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1.2px solid #efebeb;
  padding-bottom: 1em;
}
.custom-style-fontWeight {
  margin-left: 0; /* Set left margin to 0 */
  font-weight: 500; /* Set font weight to 500 */
}

.popup-header img {
  height: 26px;
  padding-right: 11px;
}

.popup-header .close img {
  height: auto;
  padding: 0;
}

.popup-header a img,
.popup-header span.pointer img {
  padding-right: 0;
}

.popup-header a,
.popup-header span.pointer {
  display: block;
  margin-left: auto;
}

.popup-header h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0;
}
.popup-header-remove h1 {
  font-size: 20px;
  margin-top: 0;
}
.custom-border {
  border-radius: 5px; /* Rounded corners */
  border: 1px solid; /* Solid 1px border */
}

.orange-button {
  padding: 8px 30px;
  border-radius: 17px;
  background-color: #f79019;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  border: 1px solid #f79019;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.orange-button:hover {
  color: #f79019;
  background-color: #ffffff;
}

.orange {
  accent-color: #f79019;
}

.ss-nav-menu-item-highlighted {
  border: 1px solid #f79019;
}

.ss-nav-menu-item-highlighted:hover {
  color: #f79019 !important;
  background-color: #ffffff;
  border-bottom-width: 1px !important;
}
.ss-action-button {
  width: 100%;
}

.app-modal-in > div > div > div,
.app-modal-in > div > div > form {
  padding: 30px 50px;
}

.app-modal {
  background-color: #ffffff;
  box-shadow: 0 5px 44px 0 rgba(0, 0, 0, 0.04);
}

.hs-bookings-container {
  width: 100%;
  float: left;
}

.hs-bookings-container > div {
  padding: 0 90px;
}

.hs-bookings-container > div .breadcrumb {
  padding: 20px 0 7px 0;
  color: #5c6771;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 0;
}

.hs-bookings-container > div .breadcrumb > li + li:before {
  color: #5c6771;
  content: "\2192";
}

.hs-bookings-container > div .content-header-title {
  text-align: left;
  padding-top: 0;
}

.hs-bookings-container > div h2 {
  color: #7c8793;
  font-size: 14px;
  padding: 20px 0;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
}

button.close > img {
  max-width: 30px;
}

.unselectable {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(47, 47, 47, 0.5);
  z-index: 10000;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.unselectable .popup-header {
  padding: 30px 50px;
}

.unselectable .popup-header-remove {
  margin: 10px;
}

.unselectable .popup-header-remove .close {
  margin-left: auto;
  margin-top: -27px;
  opacity: 1;
}

.unselectable .popup-header .close {
  margin-left: auto;
  opacity: 1;
}

.unselectable form .modal-body {
  padding: 30px 50px;
}

.container.report-label {
  padding-right: 0;
  /*padding-left: 0;*/
}

@media all and (min-width: 1000px) {
  #toggleMenuIcon {
    display: none;
  }

  #navLogoTrans {
    margin: 20px auto;
    height: 40px;
  }
}

.app-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 0;
  min-width: 20rem;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 5px 44px 0 rgba(0, 0, 0, 0.04);
}
.ss-main {
  min-height: 70vh;
}

.app-modal.modal-page {
  height: 100%;
}

.app-modal #page-content {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.app-modal #page-content {
  overflow-y: auto !important;
  overflow-x: hidden;
}
.app-modal #page-content .pointer.for-close {
  top: 20px;
  right: 20px;
  position: fixed;
  background: #ffffff;
  z-index: 999;
  padding: 0;
}
.app-modal.large {
  min-width: 50rem;
}

.app-modal.modal-page {
  min-width: 70%;
}

.app-modal-in {
  transition: opacity 0.5s ease-in;
  opacity: 1;
}

.app-modal-out {
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.app-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #979797;
  background-color: #383838;
}

.app-backdrop-in {
  transition: opacity 0.3s ease-in;
  opacity: 0.57;
}

.app-backdrop-out {
  transition: opacity 1.5s ease-out;
  opacity: 0;
}

.modal-page .about-header {
  margin-top: 20px;
}
.modal-page .container {
  max-width: 95%;
}
.modal-page footer {
  display: none;
}

.modal-page .for-close {
  padding: 20px;
}
.modal-page .for-close img {
  height: 26px;
  padding-right: 11px;
  float: right;
}
.footer {
  position: fixed;
  bottom: 0;
  background-color: whitesmoke;
  z-index: 1;
}
.buttonDivSubmit {
  margin-top: 10px;
}

.report-form.newFormCol2 {
  width: 49%;
  float: left;
  margin-right: 20px;
}
.report-form.newRightCol2 {
  width: 49%;
}
.report-form.newRightCol2 .search-container {
  padding-top: 7px;
}
.flex-container {
  display: flex; /* Enable flexbox layout */
  justify-content: space-between; /* Distribute space between items */
  align-items: center; /* Align items vertically centered */
}

.centered-message {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 60vh; /* Full height of the viewport (optional) */
  text-align: center; /* Centers text within the div */
}

@media only screen and (max-width: 430px) {
  .footer {
    position: fixed;
    bottom: -22px;
    background-color: whitesmoke;
    z-index: 1;
  }
  .poweredBy {
    margin-top: 11px;
  }
}

@media all and (max-width: 999px) {
  #toggleMenuIcon {
    height: 30px;
    width: 30px;
    background-image: url("../src/app-images/menu_icon_black.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
  #appNav {
    padding: 0 5% !important;
  }

  #navLogoTrans {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    cursor: pointer;
  }

  #navLogoContainer {
    margin-left: auto;
    margin-right: auto;
    order: 1;
  }

  #navMenuContainer {
    margin-right: inherit;
    position: absolute;
    right: 5px;
  }

  .login-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #777;
    padding: 2rem;
    opacity: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .reset-password-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #777;
    padding: 2rem;
    opacity: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .sign-up-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #777;
    padding: 2rem;
    opacity: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media only screen and (max-width: 680px) {
}
@media only screen and (max-width: 680px) {
  #ssCreateEditLocation form div {
    display: block !important;
  }
  #ssCreateEditLocation form div button {
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    width: 130px !important;
    font-size: 3vw;
  }
  #ssCreateEditLocation form.ss-form.ss-block fieldset.hs-field.ss-stand-alone {
    width: 100% !important;
  }
  .footer {
    position: fixed;
    bottom: 0px;
    background-color: whitesmoke;
    z-index: 1;

    height: 60px;
  }
  .poweredBy {
    margin-top: 11px;
  }
  .footer-copyright {
    margin-bottom: 0;
    color: #5c6771;
    font-size: 14px;
    line-height: 18px !important;
    margin-top: 15px;
  }
  .ss-footer-section {
    padding: 20px 0 0 !important;
    height: 60px;
  }
  .inputLabel span {
    width: 100%;
    display: block;
  }
  .inputLabel span input {
    margin-left: 0 !important;
  }
  .ss-report-title-h3 {
    margin-left: 0px;
  }
  .inputLabel .ss-report-title-h3 {
    margin: 0;
  }
}
@media all and (min-width: 600px) and (min-height: 975px) {
  .sign-up-modal {
    border-radius: 5px;
  }

  .login-modal {
    border-radius: 5px;
  }

  .reset-password-modal {
    border-radius: 5px;
  }
}

@media all and (max-width: 600px) {
  #appNav + div {
    padding-left: 20px;
    padding-right: 20px;
  }

  #ssApp > div {
    padding-left: 0;
    padding-right: 0;
  }

  .app-modal {
    max-width: 90%;
  }

  .app-modal-in > div > div > div,
  .app-modal-in > div > div > form {
    padding: 30px 15px;
  }

  .search #appNav + div {
    padding: 20px 5%;
    margin-bottom: 50px;
  }

  .search #appNav {
    border-bottom: 1px solid #e7e7e7;
  }

  .modal-body tr {
    display: flex;
    flex-direction: column;
  }

  .modal-content form .table.text-center .ss-button-primary,
  .modal-content form .table.text-center .ss-button-secondary {
    margin: 5px;
  }

  .sm-overflow {
    overflow: auto;
  }

  .sm-overflow::-webkit-scrollbar {
    display: none;
    height: 10px;
  }
}
@media all and (max-width: 480px) {
  #ssCreateEditLocation form div button {
    margin-right: 0px !important;
    margin-bottom: 20px !important;
    margin-left: 5px;
    width: 47% !important;
  }
  .ss-button-primary {
    padding-right: "10px";
    white-space: noWrap;
  }
}

@media all and (max-width: 415px) {
  #ssCreateEditLocation form div button {
    margin-right: 0px !important;
    margin-bottom: 20px !important;
    margin-left: 5px;
    width: 47% !important;
  }
  .ss-button-primary {
    padding-right: "10px";
    padding-left: 16px !important;
    white-space: noWrap;
  }
}
@media all and (max-width: 400px) {
  #ssCreateEditLocation form div button {
    margin-right: 0px !important;
    margin-bottom: 20px !important;
    margin-left: 5px;
    width: 47% !important;
  }
  .ss-button-primary {
    padding-right: 10px !important;
    padding-left: 9px !important;
    white-space: noWrap;
  }
}
.dtable {
  display: table !important;
}

.small-screen {
  display: none;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media all and (max-width: 999px) {
  .small-screen {
    display: block;
  }
}

.logged-in-as-info {
  width: 300px;
  line-height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  background-color: #d74747;
  z-index: 9999;
}

.logged-in-as-label {
  font-weight: 400 !important;
  margin-right: 15px;
}

.logged-in-as-info button {
  padding: 0 10px;
  margin: 0 0 0 20px;
  height: 30px;
  line-height: 1;
}

.cameraIcon {
  float: right;
  height: 5%;
  width: 5%;
  margin: 5px 5px;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.float-left {
  float: left !important;
}

.no-border {
  border: none !important;
}

.grey-bg {
  background: #f5f5f5;
}

.zone-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.csvLink-container {
  margin-top: 15px;
  text-align: right;
}
