.ss-report-header-title {
  text-align: left;
  padding-top: 0;
  font-family: Montserrat, sans-serif;
  padding-bottom: 13px;
  color: #324559;
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
}
.fontSizePopup {
  font-size: 20px;
}
@media only screen and (max-width: 431px) {
  .ss-report-header-title {
    text-align: left;
    padding-top: 0;
    font-family: Montserrat, sans-serif;
    padding-bottom: 13px;
    color: #324559;
    font-size: 21px;
    font-weight: bold;
    line-height: 38px;
  }
}
