.login-form {
  padding: 1em;
}

.ss-login {
  flex: 1;
}

@media (max-width: 668px) {
  .ss-login {
    padding: 0px 1.5em 0px 1.5em;
  }
}

.login-form-field {
  width: 100%;
  float: left;
  line-height: 19px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-form fieldset:first-of-type {
  padding-top: 0;
}

.login-form .fieldset-up {
  margin-top: -15px;
}

.login-form label {
  width: 100%;
  color: #5d6b7a;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.login-form label.ss-checkbox {
  display: flex;
  align-items: center;
}

.ss-login-form-input {
  outline: none;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  color: #324559;
  font-family: SofiaPro, sans-serif;
  padding: 0.8em 0.5em;
  float: left;
  width: 100%;
  border-radius: 0.2em;
}

.password {
  margin-bottom: 10px;
}

.forgot-password {
  width: 40%;
  color: #ff5264;
  font-size: 10px;
  font-weight: bold;
  float: left;
}

.ss-login-gray {
  color: #757b8e;
  cursor: pointer;
}
.ss-return-login-gray {
  color: #f79019;
  cursor: pointer;
  /* margin-left: 35%; */
}
.revealPassword {
  position: absolute;
  cursor: pointer;
  width: 24px;
  right: 8px;
  margin-top: 37px;
}
.revealChangePassword {
  cursor: pointer;
  width: 24px;
  margin-top: -8px;
}
.ss-forgot-label {
  color: #f79019;
  font-size: 1em;
  font-weight: 500;
  margin-top: 0.5em;
  cursor: pointer;
}

.ss-checkbox-container-checkbox {
  accent-color: #dd7a07;
  margin-right: 5px;
}

.ss-login-submit {
  font-size: 1em;
  font-weight: bold;
  width: 60%;
  padding: 1em 0em;
  display: block;
  margin: 0 auto;
}

.login-signup-panel {
  width: 100%;
  background-color: #ebecee;
  text-align: center;
}

.footer-login-signup-icon {
  display: inline-block;
  width: 23px;
}

.footer-signup-login-text {
  text-align: left;
  color: #3d3f44;
  margin-left: 7px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.social-login {
  width: 50%;
  padding-left: 10px;
  padding-bottom: 10px;
  float: left;
}

.social-login:first-child {
  padding-right: 10px;
  padding-left: 0;
}

.social-login button {
  width: 100%;
  border: 1px solid #979797;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 8px 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: #3b5998;
}

.social-login.google button {
  color: #e3190f;
}

.social-login button > span {
  display: table;
  background-repeat: no-repeat;
  background-size: auto 18px;
  background-position: center left;
  margin: auto;
  text-align: left;
}

@media all and (max-width: 600px) {
  .social-login button > span {
    width: 100px;
  }

  #g-recaptcha > div {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

.login-message-container {
  position: relative;
  top: 15px;
  left: 15px;
  float: left;
}
