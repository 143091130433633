#ssChangePassword {
  position: absolute;
  top: 70px;
  right: 0;
  left: 0;
  bottom: 0;
}

#ssChangePasswordFormContainer {
  /* height: 100%; */
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

#ssChangePasswordFormContainer > div {
  flex: 1;
  width: 500px;
  max-width: 60%;
}

#ssChangePassword footer {
  width: 100%;
}

#ssChangePassword.ss-main > hr {
  position: absolute;
  height: 1px;
  width: 100%;
  color: rgba(34, 34, 34, 0.1);
  background-color: rgba(34, 34, 34, 0.1);
}

#ssChangePassword h2 {
  font-size: 49px;
  margin: auto;
}

#ssChangePassword h3 {
  font-size: 28px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
}

#ssChangePasswordFormContainer h1 {
  font-size: 41px;
  letter-spacing: -0.8px;
  line-height: 1.025;
  font-weight: 700;
  color: #222;
  text-align: left;
  margin: 0;
  padding: 0;
}

#ssChangePasswordFormContainer p {
  padding-left: 0;
  padding-top: 19px;
}

.ss-main section p.ss-details > a {
  line-height: 1.5;
  font-weight: 400;
  margin-left: 5px;
}

.ss-main section p.ss-details > a:hover {
  font-weight: 500;
}

#ssChangePasswordFormContainer form {
  width: 100%;
  margin-top: 26px;
  background: transparent;
}
#ssChangePasswordFormContainer form fieldset {
  background: #fff;
}
#changePassword {
  width: 80%;
}

#changePasswordConfirm {
  width: 80%;
}

#changePasswordConfirmFieldset {
  margin-bottom: 20px;
}

#ssChangePasswordFormContainer footer .ss-footer-section {
  max-width: 85%;
}

.change-password header {
  width: 100%;
  float: left;
}

.modal-content {
  padding: 20px;
}

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-body {
  margin: 20px 0;
}

.ss-button-primary-modal-form {
  padding: 10px 20px;
  background-color: #ff6700; /* Adjust the color as needed */
  color: white;
  /* border: none; */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ss-button-primary-modal-form:hover {
  background-color: #e65a00; /* Adjust the hover color as needed */
}

@media all and (max-width: 1700px) {
  #ssChangePasswordFormContainer .footer-copyright {
    text-align: center;
  }

  #ssChangePasswordFormContainer .ss-footer-section {
    flex-direction: column-reverse;
  }

  #ssChangePasswordFormContainer .ss-footer-section {
    padding: 20px 0;
  }

  #ssChangePasswordFormContainer .ss-footer-icon-container {
    margin: 0 20px 20px 20px;
    text-align: center;
  }

  #ssChangePasswordFormContainer .ss-footer-ul {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  #ssChangePasswordFormContainer .ss-footer-li {
    text-align: center;
    padding: 0 15px;
  }
}

@media all and (max-width: 650px) {
  #ssChangePasswordFormContainer .ss-footer-li {
    width: 50%;
  }
}

@media all and (max-width: 1000px) {
  #ssChangePasswordFormContainer {
    width: 100%;
  }
}
