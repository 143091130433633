.ss-add-fine-time-select-container {
    display: flex;
}

.ss-add-fine-time-select-block {
    margin-top: 20px;
    margin-left: 20px;
}

.ss-add-fine-time-select {
    border: 0px solid white;
    padding:5px;
}

.ss-fine-time-select-parent {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);}

.sps-fine-weekday-select {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.sps-fine-weekday-label {
    margin-left: 0.5em;
}