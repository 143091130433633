.modal-form-change {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.modal-content-change {
  padding: 20px;
}

.alert-message h5 {
  font-weight: 500;
  font-size: 18px;
  width: 100%;
}

.alert-message button.close {
  flex: 1;
  text-align: right;
  cursor: pointer;
  opacity: 1;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/* .alert-message {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 450px;
  width: 50%;
  overflow: hidden;
  min-width: 280px;
} */

.alert-message.large {
  max-width: 650px;
}

.alert-message .modal-footer {
  padding: 20px 0;
}

.alert-message .modal-content-change > div {
  margin: 0 0;
}

.react-router-modal__container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.react-router-modal__wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.modal-form-change .modal-header {
  border-bottom: none;
}

.modal-form-change .modal-footer {
  border-top: none;
}

.up-margin {
  margin-top: -25px;
}

.dialog-block {
  background: #fff;
  z-index: 100004;
  display: block;
  width: 280px;
  max-width: 100%;
}

.dialog-block .close {
  margin-left: auto;
}

.dialog-block .modal-footer {
  padding: 15px 0 15px 15px;
}
