.button-selector {
  border-radius: 17px;
}

.button-selector > button:first-child {
  border-radius: 17px 0 0 17px;
}

.button-selector > button:last-child {
  border-radius: 0 17px 17px 0;
}

.button-selector > button {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 10px;
  cursor: pointer;
  color: #f09019;
  background: #fff;
  white-space: nowrap;
  border: 1px solid #f09019;
  border-radius: 17px;
}

.report-form .search-container .button-selector > button {
  width: 70px;
  padding: 6px;
}
.button-selector .ss-button-primary-selected {
  color: #fff;
  background-color: #f09019;
}

.hs-selector .button-selector {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  clear: both;
}
#ssApp .hs-selector .button-selector > button {
  border-color: #4daae1;
  color: #4daae1;
  font-size: 12px;
  font-weight: 500;
}
#ssApp .hs-selector .button-selector > button.ss-button-primary-middle {
  font-weight: 500 !important;
}
#ssApp .hs-selector .button-selector .ss-button-primary-selected {
  color: #fff;
  background-color: #4daae1;
}

.custom-file-picker-button {
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 30px;
  box-sizing: border-box;
  margin-left: 0;
  margin-top: 15px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

/* .custom-file-picker-button:hover {
  background-color: #f9f1f1;
  border-color: #e9e4e4;
} */

.custom-file-picker-button:disabled {
  cursor: not-allowed;
  background-color: #fef9f9e0;
  border-color: #aaa;
}

.internal-icon {
  padding: 7px;
  width: 125px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  box-sizing: border-box;
  margin-left: 0px;
  background-color: #333;
  color: #fff;
  margin: 1px;
}
