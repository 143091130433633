.busy-screen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsla(0,0%,100%,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;
}

.busy-screen.not-visible {
    display: none;
}

.busy-spinner-container {
    position: absolute;
    color: black;
    font-weight: bold;
    font-size: 40px;
}

.busy-spinner {
    z-index: 10000;
    color: gray;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
