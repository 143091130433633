#ssLanding.ss-main > header {
    background-color: black;
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.login-logo {
    max-height: 248px;
    margin-left: 37%;
    margin-top: -50px;  
}





@media all  and (max-width: 990px) {
    #ssLanding.ss-main > header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }  

    .login {
        width: 80%;
        margin:10px;
    }

    /* .login-logo {
        width: 80%;
        height: 80%;
        max-height: 300px;
        max-width: 300px;

    } */
}
@media all  and (max-width: 1920px) {
    .login-logo{
        max-height: 248px;
        margin-left: 90px;
        margin-top: -50px;

    }
}

#ssLanding .page-title {
    padding-top: 80px;
    padding-bottom: 15px;
    color: #324559;
    line-height: 48px;
}

#ssLanding.ss-main > header > h3 {
    margin: 0px auto;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #5C6771;
    line-height: 23px;
}

.ss-landing-reset-pw-success {
    text-align: center;
    align-self: center;
    display: flex;
    max-width: 35.71rem;
}

.ss-login-conditional-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ss-login-form-container {
    align-items: center;
}