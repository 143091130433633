.myControlClassName {
  border: none !important;
  border-radius: 0;
  margin-top: -8px;
  padding: 4px;
  margin-left: 3px;
  cursor: pointer;
}
.ss-error-form {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #d74747;
  margin-top: 35px;
  margin-left: -345px;
}
.zipMessage {
  margin-left: -24rem;
  margin-top: 7%;
}
.myArrowClassName {
  margin-top: -4px;
}
.custom-menu {
  height: 76px; /* Set the fixed height */
  overflow-y: auto I !important; /* Enable vertical scrolling */
}

.ss-error-formPhone {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #d74747;
  margin-top: -22px;
}
.CardNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
  position: relative;
  padding: 2px;
  height: 32px;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
}
.margin-bottom-10px {
  margin-bottom: 10px;
}
.CardNumber input {
  width: 100%;
  border-radius: 5px;
  border-color: black;
  border: 0px solid black;
  margin-left: 4%;
}
.custom-radio {
  appearance: none; /* Remove default radio styling */
  width: 20px; /* Outer circle size */
  height: 20px;
  border: 2px solid #f79019; /* Outer circle color */
  border-radius: 50%; /* Make it round */
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: none; /* Remove default box-shadow */
}

.custom-radio {
  appearance: none; /* Remove default radio styling */
  width: 20px; /* Outer circle size */
  height: 20px;
  border: 2px solid #f79019; /* Outer circle color */
  border-radius: 50%; /* Make it round */
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: none !important; /* Force remove default box-shadow */
}

.custom-radio:checked::before {
  content: "";
  width: 10px; /* Inner dot size */
  height: 10px;
  background-color: #f79019; /* Inner dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Forcefully remove any focus styles */
.custom-radio:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Remove additional styling from active or checked states */
.custom-radio:active,
.custom-radio:checked:active {
  outline: none !important;
  box-shadow: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
.margin-top-4px {
  margin-top: 4px;
}

.CardNumber label {
  position: absolute;
  top: -9px;
  left: 6px;
  padding: 2px 5px;
  font-size: 10px;
  letter-spacing: 0.7px;
  line-height: 1.24;
  white-space: nowrap;
  font-weight: 600;
  background: #fff;
}
.cardDetailDiv {
  width: 500px;
}
.cardDetailDiv .newbuttonDivSubmit {
  padding: 0px 45px 10px;
}
.cardDetailDiv .newbuttonDivSubmit button {
  margin-right: 0px !important;
}
.cardDetailDiv .modal-content .twoColDiv {
  display: flex;
  justify-content: space-between;
}
.cardDetailDiv .modal-content .inputDiv {
  display: inline-block;
  padding: 10px 30px;
}
.cardDetailDiv .modal-content .inputDiv label {
  display: block;
}
.cardDetailDiv .modal-content .inputDiv input {
  width: 100%;
  display: inline-block;
  border-radius: 2px;
  border: 1px solid #aeaeae;
  padding: 5px;
  margin-top: 5px;
}
.CardNumber.p-0 {
  padding: 20px 0;
}
.cardDetailDiv .modal-content .twoColDiv .inputDiv input {
  width: 100px;
}
.cardDetailDiv .modal-content .twoColDiv .inputDiv:last-child input {
  width: 60px;
  letter-spacing: 2px;
}

.cardDetailDiv .modal-content .ss-report-header-title {
  text-align: center;
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  padding: 0;
  font-size: 24px;
  height: 40px;
}

.inputLabel {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.deleteImage {
  margin-left: 10px;
  cursor: pointer;
  color: #f79019;
  height: 21px;
  width: 21px;
}
.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newbuttonDivSubmit {
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newbuttonDivSubmitEdit {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5vh;
}
.newbuttonDivSubmit button {
  width: 200px !important;
  margin-right: 0px !important;
  height: 40px;
  margin-top: 0 !important;
}

.newbuttonDivSubmitDelete {
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.newbuttonDivSubmitDelete button {
  width: 150px !important;
  margin-right: 0px !important;
  height: 40px;
  margin-top: 0 !important;
}

.formCreditCard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.payment-method-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;
  height: 5vh;
}

.payment-method-container-ach {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 550px;
  height: 5vh;
}

.payment-edit-options {
  display: flex;
  width: 250px;
  justify-content: left;
  align-items: center;
}
.default-button {
  width: auto;
  padding: 0px 10px;
}
.payment-edit-options .delete-button {
  width: 40px;
  margin-left: 0 !important;
}
.MT-5 {
  /* margin-top: 3%; */
}
.payment-checkbox {
  height: 5vh;
  gap: 5px;
}
.setAsDefaultButton {
  margin-left: 5px;
  padding: 3px 10px;

  cursor: pointer;
  background-color: white;
  color: #f79019;
  border: 1px solid #f79019;
  border-radius: 2rem;
}
.defaultButton {
  margin-left: 5px;
  padding: 3px 10px;
  width: 109px;
  background-color: #196ff0;
  color: white;
  border: none;
  border-radius: 2rem;
}
.processPayment {
  width: "200px";
}

.ss-button-primary-modal-form-checkout {
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  border-radius: 35px !important;
  font-weight: 500;
  background-color: #f79019;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  border: 1px solid #f79019;
  min-width: 100px;
  float: left;
  margin-right: 4px;
}

.ss-button-primary-modal-form-checkout:hover {
  background-color: white; /* Adjust the hover color as needed */
  color: #ff6700;
}

.modal-footerp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 711px) {
  .phoneError {
    margin-bottom: -1px !important;
  }
}
@media screen and (max-width: 711px) {
  .phoneError {
    margin-bottom: 16px !important;
  }
}
@media (max-width: 600px) {
  .custom-radio {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  .custom-radio:checked::before {
    width: 9px;
    height: 9px;
  }
}

@media only screen and (max-width: 880px) {
  .defaultButton {
    margin-left: 5px;
    padding: 5px 10px;
    width: 90px;
    background-color: #196ff0;
    color: white;
    border: none;
    border-radius: 2rem;
  }
  .modal-footerp {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 850px) {
  .buttonCss {
    margin-top: 20px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 680px) {
  .newbuttonDivSubmitDelete button {
    width: 130px !important;
    margin-right: 0px !important;
    height: 40px;
    margin-top: 0 !important;
  }
  .securityCheckBox {
    height: 10px;
  }
  .payment-edit-options {
    display: flex;
    width: 50%;
    justify-content: left;
    align-items: center;
  }
  .payment-edit-options .delete-button {
    width: 35px;
    margin-left: 0 !important;
  }
  .payment-edit-options .default-button {
    width: auto;
    padding: 5px 0px;
  }
  .setAsDefaultButton {
    font-size: 11px;
    padding: 5px 0px;
    width: 90px;
  }
  .payment-edit-options .default-button .deleteImage {
    margin-left: 0;
  }
  .payment-checkbox {
    height: 5vh;
    gap: 5px;
    width: 150px;
  }
  .payment-method-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
  }
  .payment-method-container-ach {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 9vh;
  }
  .CardNumber input {
    width: 97%;
    border-radius: 5px;
    border-color: black;
    border: 0px solid black;
    margin-left: 2%;
    margin-top: 1.5%;
  }
  .formCreditCard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .newbuttonDivSubmit button {
    width: 45% !important;
    /* margin-right: 10px !important; */
    height: 40px;
    margin-top: 0 !important;
  }
  .newbuttonDivSubmit.newButtonDiv {
    flex-direction: row;
    align-items: center;
    margin: 10px auto;
    width: 100%;
    text-align: center;
  }
  .newbuttonDivSubmit.newButtonDiv button {
    height: 40px;
    margin-top: 10px !important;
  }
  .processPayment {
    white-space: noWrap;
    overflow: hidden;
    padding: 10px;
  }
  .ss-error-form {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #d74747;
    margin-top: 35px;
    margin-left: 0px;
  }
  .ss-error-formPhone {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #d74747;
  }
}
