.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-column {
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-item {
    padding: 0.5rem;
}
