.ss-report-data-base {
  padding: 6px 0 6px 32px;
  min-width: 282px;
}

.ss-report-data-children {
  overflow: hidden;
  color: #324559;
  font-size: 12px;
  font-weight: bold;
  white-space: pre-wrap;
}

.ss-report-data-children.modified {
  background-color: #f79019;
}

.ss-report-data-label {
  color: #5d6b7a;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
}
